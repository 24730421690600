import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { useHistory, useLocation } from 'react-router-dom';
import {
  green,
  orange,
  grey,
  red,
  blue,
} from '@material-ui/core/colors';
import { isBeforeNow } from '../momentUtilities';
import moment from 'moment';
import { useContactGroupsQuery } from '../../hooks/contactGroups/useContactGroupsQuery';
import { useBookingsForPeriodQuery } from '../../hooks/bookings/userBookingsForPeriod';
import { useSchoolsQuery } from '../../hooks/schools/useSchoolsQuery';
import './calendar.css';
import { hasAdminRole } from '../utilities';

const getInitialDateRange = (locationStateDate) => {
  const today = new Date();
  const firstDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1, // Subtract 1 to get the previous month
    1
  );
  const end = new Date(today);
  end.setDate(today.getDate() + 60);

  if (locationStateDate) {
    // Use the provided date from location state if available
    const selectedDate = new Date(locationStateDate);
    const firstDayOfSelectedMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() - 1,
      1
    );
    const lastDayOfSelectedMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 2,
      0
    );

    return {
      start: firstDayOfSelectedMonth.toISOString(),
      end: lastDayOfSelectedMonth.toISOString(),
    };
  } else {
    // Use today's date and go back to the start of the month + 45 days
    return {
      start: firstDayOfMonth.toISOString(),
      end: end.toISOString(),
    };
  }
};

const CalendarView = ({ user }) => {
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const location = useLocation();
  const [filter, setFilter] = React.useState('CANCELLED');
  const contactGroupsQuery = useContactGroupsQuery();
  const schoolsQuery = useSchoolsQuery();

  // TODO - bookingForPeriod query issue when coming back from booking view
  // Date is persisted and bookings are disappearing

  // Date range supplied to the bookingsForPeriod query
  // Initally set to current month then as per calendar view
  const [dateRange, setDateRange] = React.useState(
    getInitialDateRange(location?.state?.date)
  );

  // Fetch bookings for the date range
  const { bookingsForPeriod, refetchBookings } =
    useBookingsForPeriodQuery(dateRange.start, dateRange.end);

  // Update the date range when the calendar view changes
  const handleDatesRender = (info) => {
    // Update the date range when the calendar view changes
    const newStart = info.view.activeStart.toISOString();
    const newEnd = info.view.activeEnd.toISOString();
    setDateRange({ start: newStart, end: newEnd });

    // Call the refetch function when the date range changes
    refetchBookings();
  };

  // Map the bookings data to the format required by FullCalendar
  const bookingsData = bookingsForPeriod?.map((booking) => {
    const acceptedBy = booking?.contacts?.find(
      (contact) => contact.status === 'accepted'
    );

    const rejectedBy = booking?.contacts?.filter(
      (contact) => contact.status === 'rejected'
    );

    // Get contactGroupId from the booking - booking.contactGroupId
    const contactGroupOfBooking = contactGroupsQuery?.data?.find(
      (cg) => cg.id === booking.contactGroupId
    );

    // Find the school name from the schoolsQuery
    const schoolOfBooking = schoolsQuery?.data?.find(
      (school) => school.id === contactGroupOfBooking?.schoolId
    );

    const schoolOfCreator = contactGroupsQuery?.data?.find(
      (cg) =>
        cg.schoolId &&
        cg.contacts.find((c) => c.id === booking.createdBy.id)
    );

    // Add in some extra status functionality
    // Past bookings = Cancelled
    // Rejected by all = Rejected
    const status =
      booking.status === 'CANCELLED'
        ? booking.status
        : acceptedBy
        ? 'ACCEPTED'
        : isBeforeNow(booking.startDate)
        ? 'CANCELLED'
        : rejectedBy.length === booking.contacts.length
        ? 'REJECTED'
        : booking.status;

    return {
      ...booking,
      id: booking.id,
      status,
      start: booking.startDate,
      end: booking.endDate,
      acceptedBy,
      adminCanView: true,
      school: schoolOfCreator?.school,
      title:
        user?.role === 'crt'
          ? schoolOfBooking
            ? schoolOfBooking?.name
            : 'N/A'
          : status === 'CANCELLED'
          ? 'Cancelled'
          : acceptedBy
          ? acceptedBy.surname === 'Coverage'
            ? `${booking.teacher} [Not Covered]`
            : `${acceptedBy.firstName} ${acceptedBy.surname}`
          : status === 'REJECTED'
          ? 'No cover found'
          : 'Organising cover',
      backgroundColor:
        status === 'CANCELLED'
          ? grey[800]
          : status === 'REJECTED'
          ? red[600]
          : acceptedBy
          ? acceptedBy.surname === 'Coverage'
            ? blue[600]
            : green[600]
          : orange[600],
    };
  });

  console.log(bookingsData);

  // Filter the bookings data based on the filter state
  const filteredBookings = React.useMemo(() => {
    return bookingsData?.filter(
      (booking) =>
        booking.status !== filter &&
        (user?.id === booking.acceptedBy?.contactId ||
          hasAdminRole(user?.role))
    );
  }, [bookingsData, filter, user]);

  const handleDateClick = (arg) => {
    // bind with an arrow function
    // todo - create booking for this day
    console.log(arg.dateStr);
  };

  // When an event is clicked, navigate to the booking view
  const handleEventClick = (arg) => {
    let calendarApi = calendarComponentRef.current.getApi();

    const path = hasAdminRole(user?.role)
      ? `../booking/${arg.event._def.publicId}/edit`
      : `../ack-booking/${arg.event._def.publicId}`;
    history.push({
      pathname: path,
      state: {
        message: 'calendar',
        view: calendarApi.currentDataManager.state.currentViewType,
      },
    });
  };

  // If the user is coming back from a booking
  // then we need to go back to the right date
  // and the right view
  const calendarComponentRef = React.useRef();
  const goToDate = (state) => {
    let calendarApi = calendarComponentRef.current.getApi();
    // console.log(calendarApi.currentDataManager.state.currentViewType);
    calendarApi.gotoDate(state.date); // call a method on the Calendar object
    calendarApi.changeView(state.view);
  };

  // Get the date from the location state and go to that date
  // and view by calling the goToDate function
  React.useEffect(() => {
    // Check if the navigation was triggered by the history stack
    const isBackNavigation = location.state?.fromHistory === true;

    if (location?.state?.date && isBackNavigation) {
      goToDate(location.state);
    }
  }, [location]);

  return (
    <FullCalendar
      ref={calendarComponentRef}
      displayEventTime={false}
      plugins={[
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin,
      ]}
      initialView={
        window.innerWidth >= 765 ? 'dayGridMonth' : 'listWeek'
      }
      weekends={false}
      dateClick={hasAdminRole(user?.role) ? handleDateClick : null}
      events={filteredBookings}
      // Handle the dates render event to update the date range
      // when the calendar view changes
      viewDidMount={handleDatesRender}
      datesSet={handleDatesRender}
      eventClick={handleEventClick}
      eventTimeFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: 'short',
      }}
      aspectRatio={matchesSmall ? 0.75 : 2}
      headerToolbar={{
        // Define custom buttons for next and previous actions
        left: matchesSmall ? 'prev,next' : 'prev,next today',
        center: 'title',
        right: matchesSmall
          ? 'today'
          : 'showAllButton dayGridMonth,listWeek',
      }}
      buttonText={{
        today: 'Today',
        list: 'List',
        month: 'Month',
      }}
      customButtons={{
        showAllButton: {
          text:
            filter === 'CANCELLED' ? 'Show All' : 'Hide Cancelled',
          click: function () {
            if (filter === 'CANCELLED') {
              setFilter('all');
            } else {
              setFilter('CANCELLED');
            }
          },
        },
      }}
      eventDidMount={(info) => {
        // Manually display the start and end date to
        // allow for bookings that span over multiple days
        if (info.view.type === 'listWeek') {
          const timeRange = [];
          timeRange.push(
            `${moment(info.event._def.extendedProps.startDate).format(
              'H:mm'
            )} - ${moment(
              info.event._def.extendedProps.endDate
            ).format('LT')}`
          );
          for (let i = 0; i < timeRange.length; i++) {
            let columnElement = document.createElement('td');
            columnElement.style.width = '150px';
            columnElement.textContent = timeRange[i];
            info.el.prepend(columnElement);
          }
        }
        // TODO - only notes for > 765
        if (
          info.view.type === 'listWeek' &&
          window.innerWidth >= 765
        ) {
          const toInjectTeacher = [];
          toInjectTeacher.push(
            info.event._def.extendedProps.teacher &&
              info.event._def.extendedProps.teacher !== 'None'
              ? `(${info.event._def.extendedProps.teacher})`
              : ''
          );
          for (let i = 0; i < toInjectTeacher.length; i++) {
            let columnElement = document.createElement('td');
            columnElement.textContent = toInjectTeacher[i];
            info.el.append(columnElement);
          }

          // List events
          const toInjectNotes = [];
          toInjectNotes.push(info.event._def.extendedProps.notes);
          for (let i = 0; i < toInjectNotes.length; i++) {
            let columnElement = document.createElement('td');
            columnElement.textContent = toInjectNotes[i];
            info.el.append(columnElement);
          }

          // List subheaders
          const tableSubHeader =
            document.querySelectorAll('.fc-list-day th');
          for (let k = 0; k < tableSubHeader.length; k++) {
            tableSubHeader[k].colSpan = 5;
          }

          const events = document.querySelectorAll('.fc-list-event');
          for (let j = 0; j < events.length; j++) {
            events[j].style.cursor = 'pointer';
          }
        }
      }}
    />
  );
};

export default CalendarView;
