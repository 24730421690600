import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import EmptyState from '../components/EmptyState';
import ContactListItem from '../components/contact/ContactListItem';
import Loading from '../components/Loading';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';
import { useContactGroupsQuery } from '../hooks/contactGroups/useContactGroupsQuery';
import { useContactsQuery } from '../hooks/contacts/useContactsQuery';
import { usePendingContactsForSchool } from '../hooks/contacts/usePendingContactsForSchool';

const Contacts = () => {
  // const classes = useStyles();
  const school = useGetSchool();
  const contactGroupsQuery = useContactGroupsQuery();
  const { data: contacts, isLoading } = useContactsQuery();

  // Are there contacts waiting to be approved?
  // These contacts should have 'requestedSchools' property
  const {
    data: pendingContactsForSchool,
    isLoading: pendingContactsIsLoading,
  } = usePendingContactsForSchool(school?.schoolId);

  // Filter contacts by status and also role !== 'superAdmin'
  const activeContacts = contacts?.filter(
    (c) =>
      c.status === 'Active' &&
      c.role !== 'superAdmin' &&
      c.id !== 'no.coverage@bustle.com.au'
  );

  const inActiveContacts = contacts?.filter(
    (c) => c.status === 'Inactive' && c.role !== 'superAdmin'
  );

  return (
    <>
      {isLoading || pendingContactsIsLoading ? (
        <Loading />
      ) : !contacts?.length ? (
        <EmptyState text="No contacts" icon="person" />
      ) : (
        <>
          {pendingContactsForSchool && (
            <List
              component="nav"
              aria-label="contact list"
              subheader={
                pendingContactsForSchool?.length ? (
                  <ListSubheader component="div" id="list-subheader">
                    Waiting for approval (
                    {pendingContactsForSchool?.length})
                  </ListSubheader>
                ) : (
                  false
                )
              }
            >
              {pendingContactsForSchool
                ?.filter((c) => c.firstName)
                .sort((a, b) => {
                  let nameA = a.firstName.toLowerCase();
                  let nameB = b.firstName.toLowerCase();
                  return nameA !== nameB
                    ? nameA < nameB
                      ? -1
                      : 1
                    : 0;
                })
                .map((contact) => {
                  return (
                    <ContactListItem
                      contact={contact}
                      key={contact.id}
                      contactListCount={0}
                      school={school}
                      waitingForApproval={true}
                    />
                  );
                })}
            </List>
          )}
          <List
            component="nav"
            aria-label="contact list"
            subheader={
              activeContacts.length ? (
                <ListSubheader component="div" id="list-subheader">
                  Active Contacts ({activeContacts?.length})
                </ListSubheader>
              ) : (
                false
              )
            }
          >
            {activeContacts
              ?.filter((c) => c.firstName)
              .sort((a, b) => {
                let nameA = a.firstName.toLowerCase();
                let nameB = b.firstName.toLowerCase();
                return nameA !== nameB ? (nameA < nameB ? -1 : 1) : 0;
              })
              .map((contact) => {
                const contactListCount =
                  contactGroupsQuery?.data?.filter((cg) =>
                    cg.contacts.find(
                      (c) =>
                        c.id === contact.id &&
                        cg.school === school.school
                    )
                  ).length;

                return (
                  <ContactListItem
                    contact={contact}
                    key={contact.id}
                    contactListCount={contactListCount - 1}
                  />
                );
              })}
          </List>
          <List
            component="nav"
            aria-label="contact list"
            subheader={
              inActiveContacts.length ? (
                <ListSubheader component="div" id="list-subheader">
                  Inactive Contacts ({inActiveContacts?.length})
                </ListSubheader>
              ) : (
                false
              )
            }
          >
            {inActiveContacts
              ?.sort((a, b) => {
                let nameA = a.firstName.toLowerCase();
                let nameB = b.firstName.toLowerCase();
                return nameA !== nameB ? (nameA < nameB ? -1 : 1) : 0;
              })
              .map((contact) => {
                const contactListCount =
                  contactGroupsQuery?.data?.filter((cg) =>
                    cg.contacts.find(
                      (c) =>
                        c.id === contact.id &&
                        cg.school === school.school
                    )
                  ).length;

                return (
                  <ContactListItem
                    contact={contact}
                    key={contact.id}
                    contactListCount={contactListCount - 1}
                  />
                );
              })}
          </List>
        </>
      )}
    </>
  );
};

export default Contacts;

// const useStyles = makeStyles((theme) => ({

// }));
