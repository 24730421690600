import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { RadioGroup, CheckboxWithLabel } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useAccount, useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { removeDuplicatesBy } from '../components/utilities';
import AddSchoolDialog from '../components/getStarted/AddSchoolDialog';
import UserAvatar from '../components/UserAvatar';
import Loading from '../components/Loading';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import useCreateContact from '../hooks/contacts/useCreateContact';
import useUpdateContact from '../hooks/contacts/useUpdateContact';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import { hasAdminRole } from '../components/utilities';

// TODO - link to placeholder terms and conditions page

const GetStarted = () => {
  const classes = useStyles();
  const history = useHistory();
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = useAccount(accounts[0]) || {};
  const contactQuery = useContactQuery(account.username);
  const contact = contactQuery.data;
  const { mutate: updateContact } = useUpdateContact();
  // might use the contact list property - empty, not approved -> show setup fields

  const { mutate: createContact } = useCreateContact();

  React.useEffect(() => {
    // if user is new, call createUser API
    if (
      contactQuery.isSuccess &&
      contactQuery.data.statusCode === 404
    ) {
      createContact({
        contact: {
          id: account.username,
          surname: account.idTokenClaims.family_name || ' ', //TODO - validate sign up form
          firstName: account.idTokenClaims.given_name,
          mobile: account.idTokenClaims.extension_Mobile,
          sub: account.idTokenClaims.sub,
          status: 'New',
          requestedSchools: null,
          role: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactQuery.data]);

  // School state
  const [schoolValue, setSchoolValue] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  // Handle chosen school validation manually
  const [schoolValueError, setSchoolValueError] =
    React.useState(false);
  // Ref required to prevent validation
  // check being run on first mount
  const isMounted = React.useRef(false);

  // Update the chosen school validation state
  React.useEffect(() => {
    if (isMounted.current) {
      if (!schoolValue.length) {
        setSchoolValueError(true);
      } else {
        setSchoolValueError(false);
      }
    } else {
      isMounted.current = true;
    }
  }, [schoolValue]);

  // If the user happens to select multiple schools
  // then changes their role to admin, trim the schoolValue
  // to the first value
  const AutoUpdateSchoolValue = () => {
    const {
      values: { role },
    } = useFormikContext();

    React.useEffect(() => {
      if (hasAdminRole(role) && schoolValue.length > 1) {
        setSchoolValue((schoolValue) => [schoolValue[0]]);
      }
    }, [role]);
    return null;
  };

  // Add school via the dialog
  const handleAddSchoolClose = (newValue) => {
    setDialogOpen(false);
    if (newValue) {
      const uniqueNames = removeDuplicatesBy((c) => c.id, newValue);
      setSchoolValue(uniqueNames);
    }
  };

  // Remove school via the dialog - not required for single school select only
  // const handleRemoveSchool = (id) => {
  //   const newArray = schoolValue.filter((school) => school.id !== id);
  //   setSchoolValue(newArray);
  // };

  // If pending approval redirect to waiting for approval screen
  if (
    contactQuery.isSuccess &&
    contactQuery.data?.status === 'PendingApproval'
  ) {
    return (
      <Redirect
        to={{
          pathname: '/waiting-for-approval',
        }}
      />
    );
  }

  return (
    <>
      <section className={classes.root}>
        <Typography variant="h6">
          Welcome, {account?.idTokenClaims.given_name} 👋
        </Typography>
        {contactQuery.isLoading ? (
          <Loading />
        ) : (
          <>
            <Typography
              variant="body1"
              color="textSecondary"
              gutterBottom
            >
              Let's get you set up
            </Typography>
            <br />
            <Formik
              initialValues={{
                role: 'crt',
                schoolValue,
                termsAndConditions: false,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.termsAndConditions) {
                  errors.termsAndConditions =
                    'You must accept the Terms and Conditions';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  setSubmitting(false);

                  const updatedContact = {
                    contact: {
                      ...contact,
                      requestedSchools: `${schoolValue.map(
                        (s) => s.id
                      )}`,
                      role: values.role,
                      status: 'PendingApproval',
                    },
                  };

                  console.log({ updatedContact });

                  updateContact(
                    {
                      contact: {
                        ...contact,
                        requestedSchools: `${schoolValue.map(
                          (s) => s.id
                        )}`,
                        role: values.role,
                        status: 'PendingApproval',
                      },
                    },
                    {
                      onSuccess: () => {
                        resetForm();
                        dispatch(
                          showInfoSnackbar('Set up completed')
                        );
                        history.push('/waiting-for-approval');
                      },
                      onError: () =>
                        dispatch(
                          showInfoSnackbar('An error occurred')
                        ),
                    }
                  );

                  resetForm();
                }, 1000);
              }}
            >
              {({
                submitForm,
                isSubmitting,
                values,
                errors,
                touched,
              }) =>
                isSubmitting ? (
                  <Loading />
                ) : (
                  <Form>
                    <div className={classes.paper}>
                      <FormControl component="fieldset" fullWidth>
                        <Typography variant="subtitle1">
                          What is your role?
                        </Typography>
                        <Field
                          component={RadioGroup}
                          aria-label="role"
                          name="role"
                        >
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <FormControlLabel
                                value="crt"
                                control={
                                  <Radio disabled={isSubmitting} />
                                }
                                label="Relief teacher"
                                disabled={isSubmitting}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControlLabel
                                value="admin"
                                control={
                                  <Radio disabled={isSubmitting} />
                                }
                                label="School's Daily Organiser"
                                disabled={isSubmitting}
                              />
                            </Grid>
                          </Grid>
                        </Field>
                        <AutoUpdateSchoolValue />
                      </FormControl>
                    </div>
                    <div
                      className={classes.paper}
                      style={{ paddingRight: 0 }}
                    >
                      <Typography variant="subtitle1">
                        {values.role === 'crt'
                          ? 'Which school do you work at?'
                          : 'Which school do you manage?'}
                      </Typography>
                      {schoolValue.length === 0 && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setDialogOpen(true)}
                          className={classes.button}
                        >
                          Select from our list of schools
                        </Button>
                      )}
                      {/* TODO - sort az */}
                      {schoolValue.length > 0 && (
                        <List dense className={classes.list}>
                          {schoolValue.map((school) => {
                            return (
                              <div
                                key={school.id}
                                className={classes.listItem}
                              >
                                <ListItem
                                  button
                                  onClick={() => setDialogOpen(true)}
                                >
                                  <ListItemAvatar>
                                    <UserAvatar
                                      size="small"
                                      label={school.name[0]}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={school.name}
                                  />
                                  <ListItemSecondaryAction>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        setDialogOpen(true)
                                      }
                                      className={classes.clearButton}
                                    >
                                      Change
                                    </Button>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </div>
                            );
                          })}
                        </List>
                      )}
                      {schoolValueError && (
                        <FormHelperText error>
                          You must select a school
                        </FormHelperText>
                      )}
                    </div>
                    <div>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="termsAndConditions"
                            Label={{
                              label: (
                                <Typography>
                                  I accept the{' '}
                                  <Typography
                                    component="a"
                                    color="primary"
                                    href="/settings"
                                    target="_blank"
                                  >
                                    Terms and Conditions
                                  </Typography>
                                </Typography>
                              ),
                            }}
                          />
                          <FormHelperText
                            error={!values.termsAndConditions}
                          >
                            {errors.termsAndConditions &&
                              touched.termsAndConditions && (
                                <span>
                                  {errors.termsAndConditions}
                                </span>
                              )}
                          </FormHelperText>
                        </FormGroup>
                      </FormControl>
                      <br />
                      <br />
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        if (schoolValue.length) {
                          submitForm();
                        } else {
                          setSchoolValueError(true);
                        }
                      }}
                    >
                      Complete Set Up
                    </Button>
                    <AddSchoolDialog
                      open={dialogOpen}
                      value={schoolValue}
                      isLoading={false}
                      onClose={handleAddSchoolClose}
                    />
                  </Form>
                )
              }
            </Formik>
          </>
        )}
      </section>
    </>
  );
};

export default GetStarted;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '34em',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
    },
    '& .MuiListItem-secondaryAction': {
      paddingRight: 0,
    },
    '& .MuiListItemSecondaryAction-root': {
      right: 0,
    },
  },
  button: {
    marginTop: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
}));
