import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
// import { useFormikContext } from 'formik';
import UserAvatar from '../UserAvatar';
import Loading from '../Loading';
import { Typography } from '@material-ui/core';
import { useSchoolsQuery } from '../../hooks/schools/useSchoolsQuery';
// import { hasAdminRole } from '../utilities';

const AddSchoolDialog = (props) => {
  const classes = useStyles();

  // Get all schools
  const { data, isLoading, isError } = useSchoolsQuery();

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  // const {
  //   values: { role },
  // } = useFormikContext();

  // Original multi-select function
  // const handleContactItemClick = (newValue, isSelected) => {
  //   if (isSelected) {
  //     // remove from list function
  //     const newArray = value.filter(
  //       (school) => school.id !== newValue[0].id
  //     );
  //     setValue(newArray);
  //   } else {
  //     const newArray = [...value, ...newValue];
  //     setValue(newArray);
  //   }
  // };

  const handleContactItemSelect = (newValue, isSelected) => {
    if (isSelected) {
      const newArray = [];
      setValue(newArray);
    } else {
      const newArray = [...newValue];
      onClose(newArray);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Select your school
      </DialogTitle>

      <DialogContent dividers className={classes.content}>
        {isError ? (
          'Sorry, no schools available at the moment. Please try again later'
        ) : isLoading ? (
          <Loading height={318} size={24} />
        ) : (
          <>
            <List
              className={classes.list}
              subheader={
                <ListSubheader
                  disableSticky
                  component="div"
                  id="contacts-subheader"
                >
                  <Box display="flex" alignItems="center">
                    Available Schools
                    <Box ml="auto">
                      <Typography
                        variant="caption"
                        color="primary"
                        component="div"
                      >
                        Not listed?
                      </Typography>
                    </Box>
                  </Box>
                </ListSubheader>
              }
            >
              {data
                ?.sort((a, b) => {
                  let nameA = a.name.toLowerCase();
                  let nameB = b.name.toLowerCase();
                  return nameA !== nameB
                    ? nameA < nameB
                      ? -1
                      : 1
                    : 0;
                })
                // filter is school status is inactive
                .filter((school) => school.status === 'active')
                .map((school) => {
                  const isSelected = value.find(
                    (c) => c.id === school.id
                  );

                  return (
                    <ListItem
                      key={school.id}
                      button
                      onClick={() =>
                        handleContactItemSelect([school], isSelected)
                      }
                    >
                      <ListItemAvatar>
                        {isSelected ? (
                          <Avatar className={classes.isSelected}>
                            <CheckIcon />
                          </Avatar>
                        ) : (
                          <UserAvatar label={school.name[0]} />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        component="div"
                        primary={school.name}
                        secondary={
                          <Typography
                            variant="caption"
                            color="textSecondary"
                          >
                            {school.address}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSchoolDialog;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  list: {
    '& .MuiListSubheader-gutters': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiListItem-gutters': {
      paddingLeft: theme.spacing(3),
    },
  },
  isSelected: {
    backgroundColor: theme.palette.success.main,
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
}));
