import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import UserAvatar from '../UserAvatar';
import Loading from '../Loading';
import EmptyState from '../EmptyState';
import { red } from '@material-ui/core/colors';

const AddContactsDialog = (props) => {
  const classes = useStyles();
  const {
    onClose,
    value: valueProp,
    open,
    contactGroups,
    isLoading,
    availableContacts,
    isPreConfirmedBooking,
    isSameDay,
    originalBooking,
    ...other
  } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleListItemClick = (newValue, availableContacts) => {
    // 1. Filter out contacts that are not available for the day
    const availableFromGroup = availableContacts.filter((ac) =>
      newValue.find((c) => c.id === ac.id)
    );

    const newArray = [...value, ...availableFromGroup];
    onClose(newArray);
  };

  const handleContactItemClick = (newValue, isSelected) => {
    if (isSelected) {
      // remove from list function
      const newArray = value.filter(
        (contact) => contact.id !== newValue[0].id
      );
      setValue(newArray);
    } else {
      const newArray = [...value, ...newValue];
      setValue(newArray);
    }
  };

  const handleContactItemSelect = (newValue, isSelected) => {
    if (isSelected) {
      const newArray = [];
      setValue(newArray);
    } else {
      const newArray = [...newValue];
      onClose(newArray);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Contacts
      </DialogTitle>

      <DialogContent dividers className={classes.content}>
        {isLoading ? (
          <Loading height={318} size={24} />
        ) : availableContacts?.length === 0 ? (
          <EmptyState text="No contacts available" icon="person" />
        ) : (
          <>
            {/* TODO - hide if there is no lists */}
            {!isPreConfirmedBooking && (
              <>
                <List className={classes.list}>
                  {contactGroups?.map((cg) => (
                    <ListItem
                      key={cg.id}
                      button
                      onClick={() =>
                        handleListItemClick(
                          cg.contacts,
                          availableContacts
                        )
                      }
                    >
                      <ListItemText primary={cg.text} />
                    </ListItem>
                  ))}
                </List>
                <Divider component="div" />
              </>
            )}
            <List
              className={classes.list}
              subheader={
                <ListSubheader
                  disableSticky
                  component="div"
                  id="contacts-subheader"
                >
                  Available Contacts
                </ListSubheader>
              }
            >
              {availableContacts
                ?.sort((a, b) => {
                  let nameA = a.firstName.toLowerCase();
                  let nameB = b.firstName.toLowerCase();
                  return nameA !== nameB
                    ? nameA < nameB
                      ? -1
                      : 1
                    : 0;
                })
                // Filter out contacts that are not Active and also role !== 'superAdmin'
                .filter(
                  (contact) =>
                    contact.id !== 'no.coverage@bustle.com.au' && // Exclude the placeholder
                    contact.status === 'Active' &&
                    contact.role !== 'superAdmin'
                )
                .map((contact) => {
                  const isSelected = value.find(
                    (c) =>
                      c.id === contact.id && c.status !== 'rejected'
                  );

                  const isAvailable = availableContacts?.find(
                    (ac) => ac.id === contact.id
                  );

                  // If the booking is a copy and the
                  // contact has previously rejected the booking
                  // then disable the contact
                  const hasRejected = originalBooking?.contacts
                    ? originalBooking?.contacts.find(
                        (c) =>
                          c.id === contact.id &&
                          c.status === 'rejected' &&
                          isSameDay
                      )
                    : false;

                  return (
                    <ListItem
                      key={contact.id}
                      disabled={
                        Boolean(!isAvailable) || Boolean(hasRejected)
                      }
                      button
                      onClick={() =>
                        isPreConfirmedBooking
                          ? handleContactItemSelect(
                              [contact],
                              isSelected
                            )
                          : handleContactItemClick(
                              [contact],
                              isSelected
                            )
                      }
                    >
                      <ListItemAvatar>
                        {isSelected ? (
                          <Avatar className={classes.isSelected}>
                            <CheckIcon />
                          </Avatar>
                        ) : hasRejected ? (
                          <Avatar className={classes.hasRejected}>
                            <ThumbDownIcon style={{ fontSize: 16 }} />
                          </Avatar>
                        ) : (
                          <UserAvatar
                            size="small"
                            label={`${contact.firstName[0]}${contact.surname[0]}`}
                          />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        component="div"
                        primary={`${contact.firstName} ${contact.surname}`}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddContactsDialog;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  list: {
    '& .MuiListItem-gutters': {
      paddingLeft: theme.spacing(4),
    },
  },
  isSelected: {
    backgroundColor: theme.palette.success.main,
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
  },
  hasRejected: {
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    backgroundColor: red[600],
  },
}));
