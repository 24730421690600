import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const light = {
  palette: {
    primary: {
      light: `#B0DBF2`,
      main: '#0D9BE7',
      mainRGB: '59,150,200',
      dark: '#045283',
    },
    secondary: {
      main: '#0D9BE7',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#0F1041',
      secondary: '#647582',
    },
    background: {
      default: '#fafbfc',
      input: '#f0f3f5',
    },
  },
  layout: {
    drawerWidth: 280,
  },
  typography: {
    fontFamily: [
      'muli',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h2: {
      fontWeight: 800,
    },
    h3: {
      fontWeight: 800,
    },
    h4: {
      fontWeight: 800,
    },
    h5: {
      fontWeight: 800,
    },
    h6: {
      fontWeight: 800,
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
    },
    button: {
      fontWeight: 700,
    },
    body1: {
      fontSize: '0.9375rem',
    },
    body2: {
      fontSize: '0.9375rem',
      fontWeight: 700,
    },
  },
  overrides: {
    MuiFilledInput: {
      input: {
        borderRadius: '4px',
        background: '#f0f3f5',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: '#fff',
      },
    },
  },
};

const dark = {
  palette: {
    primary: {
      main: '#20B8F0',
    },
    type: 'dark',
  },
  layout: {
    drawerWidth: 240,
  },
};

export const defaultTheme = createMuiTheme(light);
export const darkTheme = createMuiTheme(dark);
