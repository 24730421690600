import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UserAvatar from '../components/UserAvatar';
import { useHistory } from 'react-router-dom';
import Loading from '../components/Loading';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Formik, Form, Field } from 'formik';
import { TextField, RadioGroup } from 'formik-material-ui';
import { TimePicker } from 'formik-material-ui-pickers';
import useCreateContactGroup from '../hooks/contactGroups/useCreateContactGroup';
import useCreateSchool from '../hooks/schools/useCreateSchool';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import { useContactsQuery } from '../hooks/contacts/useContactsQuery';

const getHourAndMinute = (date) => {
  const hour = moment(date).hour();
  const minute = moment(date).minute();
  return { hour, minute };
};

const CreateSchool = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: contacts } = useContactsQuery();
  const superAdmins = contacts?.filter(
    (c) => c.role === 'superAdmin'
  );

  const handleBack = () => history.goBack();

  const createContactGroup = useCreateContactGroup();
  const { mutate: createSchool } = useCreateSchool();

  const updateSchoolDetails = (updatedData) => {
    console.log(updatedData);
    createContactGroup.mutate(
      {
        contactGroup: {
          ...updatedData,
        },
      },
      {
        onSuccess: (data) => {
          // Invalidate and refetch the query after a successful update
          // queryClient.invalidateQueries(['schools', id]);
          console.log(data);
          dispatch(showInfoSnackbar('Saved'));
          history.push(`/school/${updatedData.schoolId}`);
        },
        onError: (error) => {
          dispatch(showInfoSnackbar('An error occurred'));
        },
      }
    );
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{
            school: '',
            address: '',
            phone: '',
            email: '',
            startTime: new Date().setHours(8, 45, 0, 0),
            endTime: new Date().setHours(15, 15, 0, 0),
            status: 'active',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              setSubmitting(false);

              const start = getHourAndMinute(values.startTime);
              const end = getHourAndMinute(values.endTime);

              const newSchool = {
                name: values.school,
                address: values.address,
                phone: values.phone,
              };

              createSchool(newSchool, {
                onSuccess: (id) => {
                  // create a function to convert school name prefixed with 'all' and spaces replaced with '-'
                  // e.g 'St. Mary's School' to 'all-st-marys-school'
                  const convertSchoolName = (schoolName) => {
                    return `all-${schoolName
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/'/g, '')
                      .replace(/\./g, '')}`;
                  };

                  const updatedData = {
                    school: values.school,
                    text: convertSchoolName(values.school),
                    address: values.address,
                    phone: values.phone,
                    email: values.email,
                    startHours: start.hour,
                    startMinutes: start.minute,
                    endHours: end.hour,
                    endMinutes: end.minute,
                    status: values.status,
                    // Need to add the superAdmins as contacts
                    contacts: [
                      ...superAdmins,
                      // Add a default contact for coverage
                      {
                        id: 'no.coverage@bustle.com.au',
                        firstName: 'No',
                        surname: 'Coverage',
                        mobile: '409739235',
                        sub: '4c102afa-64f8-40b6-b0fa-222781050f5c',
                        requestedSchools: null,
                        daysNotAvailable: null,
                        status: 'Active',
                        role: 'crt',
                      },
                    ],
                    teachers: '',
                    schoolId: id,
                  };

                  // Call mutation to update the data
                  updateSchoolDetails(updatedData);
                  resetForm();
                },
                onError: (error) => {
                  console.log(error);
                },
              });

              console.log(newSchool);

              // updateSchoolDetails(updatedData);
              resetForm();
            }, 1000);
          }}
        >
          {({ submitForm, isSubmitting, values, resetForm }) =>
            isSubmitting ? (
              <Loading />
            ) : (
              <Form>
                <AppBar
                  position="static"
                  color="transparent"
                  elevation={0}
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="back"
                      onClick={handleBack}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                      className={classes.actionButton}
                      size="large"
                    >
                      Save
                    </Button>
                  </Toolbar>
                </AppBar>
                <section className={classes.root}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mb={2}
                  >
                    <div className={classes.avatar}>
                      <UserAvatar size="large" label="?" />
                    </div>
                  </Box>

                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          multiline
                          name="school"
                          type="text"
                          label="School name"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          multiline
                          name="address"
                          type="text"
                          label="Address"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          multiline
                          name="phone"
                          type="number"
                          label="Phone"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          multiline
                          name="email"
                          type="email"
                          label="Email address"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TimePicker}
                          name="startTime"
                          label="Start Time"
                          inputVariant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TimePicker}
                          name="endTime"
                          label="End Time"
                          inputVariant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel component="legend">
                          Status
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="status"
                          style={{ marginLeft: '8px' }}
                        >
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid item xs={5} sm={4}>
                              <FormControlLabel
                                value="active"
                                control={
                                  <Radio disabled={isSubmitting} />
                                }
                                label="Active"
                                disabled={isSubmitting}
                              />
                            </Grid>
                            <Grid item xs={5} sm={6}>
                              <FormControlLabel
                                value="inactive"
                                control={
                                  <Radio disabled={isSubmitting} />
                                }
                                label="Inactive"
                                disabled={isSubmitting}
                              />
                            </Grid>
                          </Grid>
                        </Field>
                      </Grid>
                    </Grid>
                  </>
                </section>
              </Form>
            )
          }
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default CreateSchool;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2),
    maxWidth: '34em',
  },
  actionButton: {
    marginLeft: 'auto',
  },
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
    '& .MuiCardContent-root': {
      paddingBottom: theme.spacing(2),
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(5),
    },
  },
}));
