import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import AddContactsDialog from './AddContactsDialog';
import UserAvatar from '../UserAvatar';
import { removeDuplicatesBy, moveItem } from '../utilities';
import { useQuery } from 'react-query';
import { useTokenQuery } from '../../hooks/useTokenQuery';
import moment from 'moment';
import { useFormikContext } from 'formik';
import Loading from '../Loading';
import { weekDays } from '../utilities';

const AddContacts = ({
  contacts,
  contactGroups,
  contactsValue,
  setContactsValue,
  originalBooking,
}) => {
  const classes = useStyles();
  const [addContactsOpen, setAddContactsOpen] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const tokenQuery = useTokenQuery();

  const {
    values: { startDate, endDate, sendSms },
  } = useFormikContext();

  const startBooking = `${moment
    .utc(startDate)
    .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;

  const endBooking = `${moment
    .utc(endDate)
    .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;

  // Get the local day names for the booking
  // as a comma separated string
  // 1. Get the length of days from booking range
  const bookingLength = moment(endBooking).diff(
    moment(startBooking),
    'days'
  );

  // 2. Get the starting index of the weekDays array
  const startDayIndex = weekDays.findIndex(
    (w) => w.substring(3, 0) === moment(startDate).format('ddd')
  );

  // 3. Re-compose the weekDays array
  const rWeekDays = [
    ...weekDays.slice(startDayIndex),
    ...weekDays.slice(0, startDayIndex),
  ];

  // 4. Create the array of substrings for the required
  // length of booking days
  const rWeekDayNames = rWeekDays
    .map((w) => w.substring(3, 0))
    .slice(0, bookingLength + 1 > 7 ? 7 : bookingLength + 1);

  // 5. Create the dates the string
  let dates = '';
  for (let i = 0; i < rWeekDayNames.length; i++) {
    dates =
      dates + (i === 0 ? rWeekDayNames[i] : `,${rWeekDayNames[i]}`);
  }

  // 6. Get the start and end day names and compare to the contacts
  // daysNotAvailable to indicate if the contact is unavailable
  const startDayName = `${moment(startDate)
    .format('dddd')
    .slice(0, 3)}`;

  // If the user has more than one contact selected
  // then switch to pre-confirmed booking, just keep
  // the first contact in the list
  React.useEffect(() => {
    if (sendSms === 'no send') {
      setContactsValue((contactsValue) =>
        contactsValue.length ? [contactsValue[0]] : []
      );
    }
  }, [sendSms, setContactsValue]);

  // If startDate or endDate changes automatically
  // refetches contacts
  React.useEffect(() => {
    if (startDate && endDate) {
      setEnabled(true);
    }
  }, [startDate, endDate]);

  // Compare originalBooking.startDate and startBooking are the same day
  const isSameDay = originalBooking?.contacts
    ? moment(originalBooking?.startDate).isSame(startBooking, 'day')
    : false;

  // Get available contacts query
  // Initially disabled until the user has clicked
  // the add contacts button
  const { data: availableContacts, isLoading } = useQuery(
    'contacts_get_available',
    async () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(
        `${apiUrl}/contacts/available?dates=${dates}&startBooking=${startBooking}&endBooking=${endBooking}`,
        {
          method: 'get',
          headers: new Headers({
            Authorization: 'Bearer ' + tokenQuery.data,
            'Content-Type': 'application/json',
          }),
        }
      ).then((res) => res.json());
    },
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime: 0, // disable cache
      onSettled: () => setEnabled(false),
      onSuccess: (data) => {
        // If there are already contacts selected
        // update the selected contacts
        // we're refetching with date changes
        if (contactsValue.length > 0) {
          const updatedContacts = contactsValue.filter(
            (contact) => data.find((ac) => ac.id === contact.id) // ??? This is contactId when coming from the API
          );
          setContactsValue(updatedContacts);
        }
      },
    }
  );

  const handleClickAddContacts = () => {
    setEnabled(!!tokenQuery); // refetch available contacts
    setAddContactsOpen(true);
  };

  const handleAddContactsClose = (newValue) => {
    setAddContactsOpen(false);
    if (newValue) {
      const uniqueNames = removeDuplicatesBy((c) => c.id, newValue);
      setContactsValue(uniqueNames);
    }
  };

  const handleReorderContacts = (index, direction) => {
    const newArray = moveItem(contactsValue, index, direction);
    setContactsValue(newArray);
  };

  const handleRemoveContact = (contactId) => {
    const newArray = contactsValue.filter(
      (contact) => contact.id !== contactId
    );
    setContactsValue(newArray);
  };

  const handleClearContacts = () => setContactsValue([]);

  return (
    <>
      <Box display="flex" width="100%" py={1} pl={2} pr={1} mb={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickAddContacts}
        >
          {sendSms === 'send'
            ? 'Add contacts(s)'
            : 'Select a contact (Optional)'}
        </Button>
        {contactsValue.length > 0 && (
          <Box ml="auto">
            <Button color="primary" onClick={handleClearContacts}>
              Clear All
            </Button>
          </Box>
        )}
      </Box>
      {isLoading && <Loading height={40} size={24} />}
      {!isLoading && contactsValue.length > 0 && (
        <List dense className={classes.contactGroupList}>
          {contactsValue
            .filter((contact) => contact.status !== 'rejected') //Filter out contacts if they rejected previous booking
            .map((contact, index) => {
              return (
                <div key={contact.id} className={classes.listItem}>
                  <ListItem button>
                    <ListItemAvatar>
                      <UserAvatar
                        size="small"
                        label={`${contact.firstName[0]}${contact.surname[0]}
                      `}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${contact.firstName} ${contact.surname}`}
                      secondary={
                        <Typography color="error">
                          {contact.daysNotAvailable &&
                          contact.daysNotAvailable
                            .split(',')
                            .includes(startDayName)
                            ? `Not available on ${startDayName}`
                            : ''}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        disabled={index === 0}
                        edge="end"
                        aria-label="move up"
                        onClick={() =>
                          handleReorderContacts(index, 'up')
                        }
                        className={classes.moveButton}
                      >
                        <ArrowUpIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        disabled={index === contactsValue.length - 1}
                        edge="end"
                        aria-label="move down"
                        onClick={() =>
                          handleReorderContacts(index, 'down')
                        }
                        className={classes.moveButton}
                      >
                        <ArrowDownIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          handleRemoveContact(contact.id)
                        }
                        className={classes.clearButton}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              );
            })}
        </List>
      )}
      <AddContactsDialog
        classes={{
          paper: classes.paper,
        }}
        id="add-contacts-menu"
        keepMounted
        open={addContactsOpen}
        onClose={handleAddContactsClose}
        value={contactsValue}
        contacts={contacts}
        availableContacts={availableContacts}
        contactGroups={contactGroups}
        isLoading={isLoading}
        isPreConfirmedBooking={Boolean(sendSms === 'no send')}
        isSameDay={isSameDay}
        originalBooking={originalBooking}
      />
    </>
  );
};

export default AddContacts;

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '80%',
    maxHeight: 435,
  },
  contactGroupList: {
    paddingTop: 0,
    width: '100%',
  },
  moveButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  listItem: {
    '& .MuiListItem-secondaryAction': {
      paddingRight: theme.spacing(14),
    },
    '& .MuiListItemAvatar-root': {
      minWidth: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(5),
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& li .MuiListItemSecondaryAction-root': {
        display: 'none',
      },
      '& li:hover .MuiListItemSecondaryAction-root': {
        display: 'inherit',
      },
    },
  },
}));
